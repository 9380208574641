<template>
    <div>
        <component
            :is="getFormieComponent('formField')"
            :field="field"
        >
            <div
                v-for="(option, index) in field.options"
                :key="index"
            >
                <component
                    :is="getFormieComponent('radio')"
                    v-model="value"
                    :input-bindings="getField(field).getInputBindings()"
                    :disabled="option?.disabled ?? null"
                    :value="option.value"
                >
                    {{ option.label }}
                </component>
            </div>
        </component>
    </div>
</template>

<script setup>
defineProps({
    field: {
        type: Object,
        required: true,
    }
});

const value = defineModel({ type: String, required: true });
</script>
